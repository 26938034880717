import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ContentWrapper from '../../components/share/contentWrapper'
import ImageBackground from '../../components/share/imageBackground'

import customMedia from '../../utils/customMedia'

const Header = props => {
  const {
    background,
    year,
    url,
    overlayColor,
    icon,
    iconStyle,
    brandName,
    content,
    linkStyle,
    contentStyle = {},
  } = props

  const defaultStyle = { marginTop: 50, flexWrap: 'nowrap' }

  return (
    <ImageBackground
      background={background}
      overlayColor={overlayColor}
      style={{ height: '600px' }}
      skew>
      <ContentWrapper style={{ ...defaultStyle, ...contentStyle }}>
        <Icon src={icon} style={iconStyle} />
        <Founded>
          {`Founded in ${year} -- `}
          <Url href={`http://${url}`} style={linkStyle}>
            {url}
          </Url>
        </Founded>
        <BrandName>{brandName}</BrandName>
        <Content>{content}</Content>
      </ContentWrapper>
    </ImageBackground>
  )
}

Header.propTypes = {
  background: PropTypes.object.isRequired,
  content: PropTypes.node,
  contentStyle: PropTypes.object,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  overlayColor: PropTypes.string,
  url: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
}

export default Header

const Icon = styled.img`
  width: 70px;
  height: 70px;
`

const Founded = styled.div`
  margin: 20px 0;
  font-size: 12px;
`

const Url = styled.a`
  color: white;
  font-weight: 600;
  text-decoration: none;
`

const BrandName = styled.h1`
  font-size: 48px;
  font-weight: 300;
  margin: 0 0 20px 0;
`

const Content = styled.article`
  width: 100%;
  font-weight: 300;

  ${customMedia.greaterThan('mobile')`
    width:65%;
  `};
`
