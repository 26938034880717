import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Back = props => {
  const { to = '/case-studies' } = props

  return (
    <Wrapper to={to}>
      <FontAwesomeIcon
        icon={['fal', 'arrow-left']}
        style={{ marginRight: 5 }}
      />
      Back to all case studies
    </Wrapper>
  )
}

export default Back

const Wrapper = styled(Link)`
  margin-bottom: 10px;
  color: #8c8c8e;
  text-decoration: none;

  &:hover {
    color: #02b7e2;
  }
`
