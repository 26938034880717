import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customMedia from '../../utils/customMedia'

import storiesData from '../../data/stories'

const query = graphql`
  query {
    BelleProperty: file(relativePath: { eq: "caseStudies/box-belle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Harris: file(relativePath: { eq: "caseStudies/box-harris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LJHooker: file(relativePath: { eq: "caseStudies/box-lj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Cunninghams: file(relativePath: { eq: "caseStudies/box-cunninghams.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BarryPlant: file(relativePath: { eq: "caseStudies/box-barryplant.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TheAgency: file(relativePath: { eq: "caseStudies/box-agency.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LJHookerIcon: file(relativePath: { eq: "brandLogos/ljHooker.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    BellePropertyIcon: file(
      relativePath: { eq: "brandLogos/belleProperty.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    TheAgencyIcon: file(relativePath: { eq: "brandLogos/theAgency.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    HarrisIcon: file(relativePath: { eq: "brandLogos/harris.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    CunninghamsIcon: file(relativePath: { eq: "brandLogos/cunninghams.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    BarryPlantIcon: file(relativePath: { eq: "brandLogos/barryPlant.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const BrandBox = props => {
  const { brandName, iconStyle, showContent = true } = props
  const brand = storiesData.find(item => item.name === brandName)
  const { title, link, brandIcon, content } = brand

  const brandIconName = `${brandName}Icon`

  return (
    <StaticQuery
      query={query}
      render={data => (
        <Wrapper to={link}>
          <Header>
            <Background fluid={data[brandName].childImageSharp.fluid} />
            <BrandIcon src={brandIcon} style={iconStyle} />
            <Overlay />
          </Header>

          <Footer showContent={showContent}>
            {showContent && (
              <Fragment>
                <Title>{title}</Title>
                <Content>{content()}</Content>
              </Fragment>
            )}

            <Flex>
              <FooterBrandIcon
                fluid={data[brandIconName].childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain', objectPosition: 'left' }}
              />
              <StoryLink>
                Read Story
                <FontAwesomeIcon
                  icon={['fal', 'arrow-right']}
                  style={{ width: '0.6em', marginLeft: 10 }}
                />
              </StoryLink>
            </Flex>
          </Footer>
        </Wrapper>
      )}
    />
  )
}

BrandBox.propTypes = {
  brandName: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  showContent: PropTypes.bool,
}

export default BrandBox

const StoryLink = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: Caveat;
  color: #02b7e2;

  ${customMedia.greaterThan('desktop')`
    color: #000000;
    font-size: 16px;
  `};
`

const BrandIcon = styled.img`
  display: none;
  position: absolute;
  width: 75%;
  height: 100px;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const Overlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
`

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);

    ${StoryLink} {
      color: #02b7e2;
    }

    ${BrandIcon} {
      display: block;
    }

    ${Overlay} {
      display: block;
    }
  }

  ${customMedia.greaterThan('mobile')`
    margin: 0 10px 10px 0;
    width: calc(100% / 3 - 10px);
    text-align: center;

    &:nth-child(3n) {
      margin-right: 0;
    };
  `};
`

const Background = styled(Img)`
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  font-size: 14px;
  color: #404041;

  ${customMedia.greaterThan('mobile')`
    height: ${props => (props.showContent ? '400px' : 'auto')}
  `};
`

const Title = styled.div`
  color: #8c8c8e;
`

const Content = styled.div`
  text-align: start;
  margin-top: 20px;
`

const Header = styled.div`
  position: relative;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  margin-top: auto;
`

const FooterBrandIcon = styled(Img)`
  width: 75px;
  height: 25px;
`
