import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const Details = props => {
  const { sizes, integrations, customTitle, customData } = props

  return (
    <Wrapper>
      <Column>
        <Title>SIZE</Title>
        {sizes.map((size, index) => (
          <Content key={`size_${index}`}>{size}</Content>
        ))}
      </Column>
      <Column>
        <Title>INTEGRATIONS</Title>
        {integrations.map((integration, index) => (
          <Content key={`integration_${index}`}>{integration}</Content>
        ))}
      </Column>
      <CustomColumn>
        <Title>{customTitle}</Title>
        {customData.map((data, index) => (
          <Content key={`customData_${index}`}>{data}</Content>
        ))}
      </CustomColumn>
    </Wrapper>
  )
}

Details.propTypes = {
  customData: PropTypes.array,
  customTitle: PropTypes.string,
  integrations: PropTypes.array,
  sizes: PropTypes.array,
}

export default Details

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  font-family: Open Sans;
  font-size: 14px;
`
const Title = styled.div`
  font-weight: 600;
  margin: 20px 0 20px 0;

  ${customMedia.greaterThan('mobile')`
    margin: 0 0 20px 0;
  `};
`

const Column = styled.div`
  margin-right: 25px;

  ${customMedia.greaterThan('mobile')`
    margin-right: 50px;
  `};
`

const Content = styled.div`
  margin-bottom: 5px;
`

const CustomColumn = styled.div`
  ${customMedia.greaterThan('mobile')`
    margin-left: auto;
  `};
`
