import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import Quote from '../../components/share/quote'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import brandIcon from '../../images/brandLogos/cunninghams.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-cunninghams.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Cunninghams = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="1991"
              iconStyle={{ width: '125px' }}
              url="cunninghamsre.com.au"
              brandName="Cunninghams Real Estate"
              icon={brandIcon}
              overlayColor="linear-gradient(115.02deg, rgba(0, 148, 255, 0.7) -5.95%, rgba(0, 209, 255, 0.7) 94.11%)"
              content={
                <Fragment>
                  Boutique 3 office group with over 10% marketshare across
                  Sydney’s Northern Beaches with offices in Manly, Dee Why and
                  Balgowlah
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['Boutique', '3 Offices']}
                integrations={[
                  'Xero',
                  'Agentbox',
                  'Realhub',
                  'Print Services',
                  'Real Studios',
                ]}
                customTitle="# OF SALES IN 2018"
                customData={['400+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={`“There’s a level of intelligence behind Realhub that makes our jobs that much easier.”`}
              />
              <IntroRow
                descWidth="85%"
                title="Many agencies are complicit, waiting to see what the competition will do and following it, not Cunninghams."
                content={
                  <Fragment>
                    <Paragraph>
                      Highly regarded in the industry as both thought leaders
                      and true innovators they are always on the lookout for the
                      most powerful technology to elevate their business to
                      success.
                    </Paragraph>
                    <Paragraph>
                      The team at Cunningham’s is on a mission to be the best,
                      in a highly competitive market they’ve continued to see
                      exceptional growth in their X years of operation. From a
                      very early point on in Realhub’s life, the product caught
                      the attention of ex-REIA president John Cunningham’s and
                      the team. Having been content and well served by an
                      existing marketing provider for over 20 years there was a
                      huge risk inherent in moving their business away from what
                      was already working. But the team saw an even bigger,
                      trying to compete in the market against other agencies
                      with more innovative technology.
                    </Paragraph>
                    <Paragraph>
                      Utilising the full range of services Realhub had to offer,
                      from the online platform and print marketing to digital
                      products and video/photography services Cunningham’s has
                      seen amazing benefits across their entire business.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                desc={`“Sometimes you’ve got to make a quantum leap, you’ve gotta take a leap of faith with innovation and lead the way. Change is inevitable. It’s the only constant. Embrace it, go with it, because if you don’t you’ll just get left behind.”`}
                descStyle={{ width: '100%' }}
                agentImage={data.agentImage.childImageSharp.fluid}
                agentName="John Cunningham"
                title="Founder & Director"
                agencyName="Cunninghams"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="75%"
                content={
                  <Fragment>
                    <Paragraph>
                      Cunningham’s Real Estate took the bull by the horns and
                      said we’re going to lead the pack, not wait and see. And
                      they’ve never once regretted their decision.
                    </Paragraph>
                    <Paragraph>
                      Across their office, and externally with their VA support
                      network their artwork creation, brand moderation and
                      inter-office communication are faster, easier and more
                      controlled than anything they were able to achieve
                      previously.
                    </Paragraph>
                    <Paragraph>
                      Taking the leap is continuously paying off and has seen
                      them able to take up a 10% market share across the entire
                      Northern Beaches with just 3 offices and under 100 staff.
                    </Paragraph>

                    <Paragraph>
                      Get business changing software to scale up your future.{' '}
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc="It's an enhancer to what we do as agents on the ground. It's still about relationships with sellers, buyers and tenants."
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox
                  brandName="BelleProperty"
                  iconStyle={{ height: '50px' }}
                />
                <BrandBox brandName="Harris" />
                <BrandBox brandName="LJHooker" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default Cunninghams

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
