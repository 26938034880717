import React, { Fragment } from 'react'
import styled from 'styled-components'

import { brandLogoMedia } from '../components/share/mediaImport'

const storiesData = [
  {
    name: 'BelleProperty',
    title: 'Fast growing, Brand Focused market leaders.',
    link: '/case-studies/belle-property',
    brandIcon: brandLogoMedia.brandBellePropertyIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          A unique boutique approach to a national business of franchises.
        </Paragraph>

        <Paragraph>
          Over <strong>90 offices </strong>
          across the eastern coast of Australia and one of the most highly
          regarded brands in the industry their requirements for perfect brand
          moderation and asset delivery are second to none.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'Harris',
    title: 'Fast growing, Brand Focused market leaders.',
    link: '/case-studies/harris',
    brandIcon: brandLogoMedia.brandHarrisIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          South Australia’s largest agency and{' '}
          <strong>industry thought leading </strong>
          approach meant onboarding required a{' '}
          <strong>few major system overhauls.</strong>
        </Paragraph>

        <Paragraph>
          But their team’s efficiencies skyrocketed and across all their offices
          they’ve seen dramatic improvements in their quality of work.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'LJHooker',
    title: 'Australia’s oldest and most well known Franchise group.',
    link: '/case-studies/lj-hooker',
    brandIcon: brandLogoMedia.brandLJHookerIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          <strong>Since 1928 </strong>
          LJ Hooker has grown into one of the most prestigious Real Estate
          companies on the planet.
        </Paragraph>
        <Paragraph>
          With over <strong>730 Offices </strong>
          and more than <strong>8000 employees </strong>
          they know more than anyone the importance of staying on top of the
          technology landscape.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'BarryPlant',
    title: 'Victoria’s largest  and fastest expanding franchise group',
    link: '/case-studies/barry-plant',
    brandIcon: brandLogoMedia.brandBarryPlantIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          A far reaching brand with over 75 offices in metropolitan Melbourne
          and now expanding through South Australia and Queensland Barry Plant
          was in desperate need of a unified system.
        </Paragraph>
        <Paragraph>
          With humble beginnings in 1958 the long running group is now stronger
          than ever.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'TheAgency',
    title: 'The new wave of technology focused, industry favourites',
    link: '/case-studies/the-agency',
    brandIcon: brandLogoMedia.brandTheAgencyIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          A <strong> unique and appealling </strong>
          offering to the market, starting from a strong base of
          <strong> well established agents.</strong>
        </Paragraph>
        <Paragraph>
          Growing faster every day, their unique offering and tech driven
          back-end is seeing more and agents take up their brand with impressive
          speed.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'Cunninghams',
    title: 'Fast growing, Brand Focused market leaders.',
    link: '/case-studies/cunninghams',
    brandIcon: brandLogoMedia.brandCunninghamsIconSvg,
    content: () => (
      <Fragment>
        <Paragraph>
          Taking the bull by the horns and saying we’re going to lead the pack,
          not wait and see. And they’ve never once regretted their decision.
        </Paragraph>
        <Paragraph>
          Across their office, artwork creation, brand moderation and
          inter-office communication are faster, easier and more controlled ever
          before.
        </Paragraph>
      </Fragment>
    ),
  },
]

export default storiesData

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`
